import $ from 'jquery';
import { onListItemHref, Viewport } from 'Common/utils';

export default class FooterController {
    static _instance = null;

    static get instance() {
        if (!this._instance) this._instance = new this();
        return this._instance;
    }

    static initNavSection(id, expanded = false) {
        const ctrl = this.instance;
        let lastMobile = Viewport.viewport.is.mobile;

        $(`#${id} ul > li`).on('click', onListItemHref);
        $(`#${id} a.nav-category`).on('click', ctrl.sectionToggler(id));
        if (expanded) ctrl.openNavSection(id);

        Viewport.onChange(() => {
            const { isMobile } = ctrl;

            if (isMobile !== lastMobile) {
                if (!isMobile) {
                    $(`#${id} .nav-list`).css('max-height', 'none');
                } else if (ctrl.open === id) {
                    $(`#${id} .nav-list`).css('max-height', Math.ceil($(`#${id} .nav-list ul`).outerHeight(true)));
                } else {
                    $(`#${id} .nav-list`).css('max-height', 0);
                }
                lastMobile = isMobile;
            }
        });
    }

    open = null;

    get isTouch() {
        return Viewport.viewport.is.touch;
    }

    get isMobile() {
        return Viewport.viewport.is.mobile;
    }

    sectionOpener = (id) => () => this.openNavSection(id);
    sectionCloser = (id) => () => this.closeNavSection(id);
    sectionToggler = (id) => () => this.toggleNavSection(id);

    toggleNavSection(id) {
        if (id) {
            if (id === this.open) this.closeNavSection(id);
            else this.openNavSection(id);
        }
    }

    openNavSection(id) {
        if (this.open !== id) {
            if (this.open) this.closeNavSection(this.open);
            if (id) {
                const list = $(`#${id} .nav-list`);

                $(`#${id}`).addClass('expanded');
                list.attr('aria-expanded', 'true').css(
                    'max-height',
                    this.isMobile ? list.find('ul').outerHeight(true) : 'none'
                );
            }
            this.open = id;
        }
    }

    closeNavSection(id) {
        if (id) {
            const list = $(`#${id} .nav-list`);

            $(`#${id}`).removeClass('expanded');
            list.attr('aria-expanded', 'false').css('max-height', this.isMobile ? 0 : 'none');
        }
        this.open = null;
    }
}
