import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import * as types from 'Common/types';
import * as PRODUCT from '~config/product';
import { ProductBadge, ProductPrice, ProductInput } from 'Common/components/product';
import { NumberInput } from 'Common/components/ui';
import { NotificationSubscription as NotifyMe } from 'Common/containers/customer';

function buildColsFromAttributes(variants = []) {
    const cols = new Set();

    for (const variant of variants) {
        for (const { IsSpecification, AttributeDisplayName } of variant.Attributes) {
            if (IsSpecification) cols.add([AttributeDisplayName]);
        }
    }

    return [...cols];
}

function getFirstBadge(badges) {
    return badges?.length ? badges.slice(0, 1) : badges;
}

export default function CatalogCard({
    className,
    googleRecaptchaV3Sitekey,
    product,
    onAddMultipleToCart,
    isUserAuthenticated,
    checkoutPageLink,
    gtmListValue,
    itemStatusTooltip = true,
    totalQty = 0,
    useRecaptcha,
}) {
    const id = React.useRef(`CC_${v4()}`);
    const [qtyMap, setQtyMap] = React.useState({});
    const facets = React.useMemo(() => product.Facets, [product.Facets]);
    const badge = React.useMemo(() => {
        const arr = [...new Set(product.MarketingBadges?.map((b) => b.toUpperCase()))];

        return getFirstBadge(arr);
    }, [product.MarketingBadges]);

    const props = React.useMemo(
        () => ({ checkoutPageLink, isUserAuthenticated, gtmListValue }),
        [checkoutPageLink, isUserAuthenticated, gtmListValue]
    );

    const quantity = React.useMemo(() => Object.values(qtyMap).reduce((sum, x) => sum + x, 0), [qtyMap]);

    const stockStatus = React.useMemo(
        () => ({
            code: product.Code,
            statusDisplayName: product.StockStatusLabel,
            hasStock: product.StockStatus === 'InStock',
        }),
        [product]
    );

    const onQtyChange = React.useCallback(
        (qty, code) => {
            setQtyMap({ ...qtyMap, [code]: qty });
        },
        [qtyMap]
    );

    const cols = React.useMemo(
        () => facets?.map((f) => [f.AttributeDisplayName, f.DisplayName]) ?? buildColsFromAttributes(product.Children),
        [facets, product.Children]
    );

    const key = React.useCallback((...args) => [id.current, ...args].join('__'), []);
    const onTableAddToCart = React.useCallback(
        (_1, _2, opts) => onAddMultipleToCart(qtyMap, opts),
        [onAddMultipleToCart, qtyMap]
    );

    const goToProduct = React.useCallback(() => {
        window.location.href = product.ContentUrl;
    }, [product]);

    return (
        <div className={cx('CatalogCard col-12 col-md-6 col-lg-4', className)}>
            <div className="CatalogCard__Container">
                <div>
                    <div className="CatalogCard__Container__Top">
                        <ProductBadge badges={badge} />
                    </div>
                    <a role="link" href={product.ContentUrl}>
                        <img
                            alt={product.ProductName}
                            src={`${product.DefaultImageUrl}?format=png&height=300&width=300${
                                PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                            }`}
                        />
                    </a>
                    <a role="link" href={product.ContentUrl}>
                        <p className="CatalogCard__Container__Title">{product.ProductName}</p>
                    </a>
                    <div className="CatalogCard__Container__FacetTable" id={id.current}>
                        {cols.length ? (
                            <div className="CatalogCard__Container__FacetTable__Body">
                                <table>
                                    <tbody>
                                        {product?.Children?.map((p) => (
                                            <tr
                                                className={goToProduct ? 'clickable' : null}
                                                onClick={goToProduct}
                                                key={key('body', 'row', p.Code)}
                                            >
                                                {cols.map(([c], i) => (
                                                    <td key={key('body', 'cell', c, p.Code)}>
                                                        {i === 0 ? (
                                                            <div className="product-badge">
                                                                {getFirstBadge(p.MarketingBadges)}
                                                            </div>
                                                        ) : null}
                                                        {p.Attributes.find(
                                                            (a) => a.AttributeDisplayName === c
                                                        )?.Values?.join(', ') ?? ''}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                        <div className="CatalogCard__Container__FacetTable__Tail">
                            <table>
                                <tbody>
                                    {product?.Children?.map((v) => (
                                        <tr key={key('tail', 'price', v.Code)}>
                                            <td>
                                                <ProductPrice
                                                    priceInfo={{
                                                        price: v.Price,
                                                        useQtySalePrice:
                                                            v.Price.QtySalePrices != null &&
                                                            Object.keys(v.Price.QtySalePrices).length > 0,
                                                    }}
                                                    quantity={qtyMap[v.Code] ?? 0}
                                                />
                                            </td>
                                            <td className="p-1">
                                                {v.StockStatus === 'InStock' ? (
                                                    <div className="CatalogCard__Container__FacetTable__Tail__Input">
                                                        <NumberInput
                                                            min={0}
                                                            step={1}
                                                            noDecimal
                                                            value={qtyMap[v.Code] ?? 0}
                                                            onChange={(n) => onQtyChange(n, v.Code)}
                                                            useStepBtns
                                                        />
                                                    </div>
                                                ) : (
                                                    <NotifyMe
                                                        googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                                                        useRecaptcha={useRecaptcha}
                                                        code={v.Code}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ProductInput
                    className="ProductListView__input"
                    {...props}
                    quantity={quantity}
                    onAddToCart={onTableAddToCart}
                    currentItemStatus={stockStatus}
                    productInfo={product}
                    currentChildren={[product]}
                    statusTooltip={itemStatusTooltip}
                    totalQty={totalQty}
                    hideWhenOOS
                />
            </div>
        </div>
    );
}

CatalogCard.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
    googleRecaptchaV3Sitekey: px.string,
    gtmListValue: px.string,
    product: types.Product,
    onAddMultipleToCart: px.func,
    isUserAuthenticated: px.bool,
    itemStatusTooltip: px.bool,
    totalQty: px.number,
    useRecaptcha: px.bool,
};
